import React from "react";

import Layout from "components/Layout";
import Container from "components/Container";
import Intro from "components/Intro";
import { SEO } from "components/SEO";

const ImprintPage = () => {
	return (
		<Layout pageName="imprint">
			<SEO
				title="Blue Medical Imprint"
				description="Imprint information of the Blue Medical Switzerland website."
			/>
			<Intro className="imprint__intro imprint__wrap">
				<h1>Imprint</h1>
			</Intro>

			<Container sectionName="imprint__wrap imprint__container imprint__address">
				<h2 className="copy-large--lh">Company Information</h2>
				<address>
					<p>
						Blue Medical AG
						<br />
						Kirchbergstrasse 160
						<br />
						3400 Burgdorf
						<br />
						Switzerland
					</p>
					<p>
						Phone: +41 34 421 81 11
						<br />
						E-mail:{" "}
						<a href="mailto:welcome@bluemedical.swiss">
							welcome@bluemedical.swiss
						</a>{" "}
						<br />
						Internet:{" "}
						<a href="http://www.bluemedical.swiss/">
							www.bluemedical.swiss
						</a>
					</p>
					<p>
						Commercial register: Kanton Bern
						<br />
						UID:{" "}
						<a href="https://be.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=CHE-112.366.508">
							CHE-112.366.508
						</a>
					</p>
				</address>
			</Container>
			<Container sectionName="imprint__wrap imprint__container imprint__content">
				<h2 className="copy-large--lh">Content</h2>
				<p>
					Use of this website, its contents or any reliance thereon is
					user’s own responsibility. Blue Medical AG accepts no
					responsibility and assumes no liability for uninterrupted or
					error-free availability and accessibility, accuracy of the
					website or the possibility of access or interaction, a
					particular characteristic, suitability, merchantability or
					fitness for a particular purpose. Exclusion of liability
					does not apply in cases of intentional or gross negligent
					conduct or where liability cannot be legally
					excluded/limited.
				</p>
				<p>
					The website is provided on an “as is” basis, without
					warranty or guaranty of any kind. Blue Medical AG accepts no
					responsibility and assumes no liability (whether explicit,
					implicit or otherwise), that (i) the contents of the website
					are complete, accurate, error-free, reliable and up-to-date;
					(ii) no rights of third parties are violated; (iii) access
					to the website is possible at all times, complete and
					error-free; (iv) the website is safe; (v) information,
					suggestions or opinions on the website are accurate; (vi)
					the website is suitable for a particular purpose.
				</p>
				<p>
					Blue Medical AG reserves the right to restrict or terminate
					access to the website (or any functions or parts thereof) at
					any time. Neither Blue Medical AG nor any other party
					involved in the creation, production or making-available of
					the website shall be held responsible or liable for direct,
					indirect, random, immaterial or consequential damages,
					costs, losses or obligations resulting from the access,
					usage, impossibility of usage or any changes to the contents
					of the website or from any action/inaction on behalf of Blue
					Medical AG in respect of an electronic message received from
					any user.
				</p>
				<p>
					This website and its contents can be changed, amended,
					altered or substituted at any time without prior notice. Any
					statement on the website shall be nonobligatory and – absent
					explicit statement to the contrary – shall not constitute a
					contractual offer. Errors and omissions excepted.
				</p>
			</Container>
			<Container sectionName="imprint__wrap imprint__container imprint__links">
				<h2 className="copy-large--lh">Links</h2>
				<p>
					The website may contain links to websites of third parties.
					Blue Medical AG does not have any influence or impact on the
					contents of such third-party-websites or any changes made
					thereto and assumes no liability for contents, accuracy or
					functionality of these websites or the secure/risk-free
					access or retrieval of the third-party-website the link is
					leading to. In particular, Blue Medical AG assumes no
					liability in respect of violations or omissions of data
					protection laws by third parties. By providing the link on
					its website Blue Medical AG does not embrace or endorse such
					third-party-website or their respective contents. Blue
					Medical AG recommends to carefully read and follow possible
					legal terms and data protection policies of these websites.
					Access, retrieval and utilisation of such
					third-party-websites and possible services is at the user’s
					sole responsibility and risk.
				</p>
			</Container>
			<Container sectionName="imprint__wrap imprint__container imprint__property">
				<h2 className="copy-large--lh">Intellectual Property</h2>
				<p>
					The website contains elements and content which do or might
					enjoy protection (pursuant to e.g. Copyright and Allied
					Copyright Law, Trademark Law, Patent/Utility Model Law,
					Design Law, Competition Law or other laws) for the benefit
					of Blue Medical AG (or third parties). Blue Medical AG
					expressly reserves any and all rights in and to the same.
					Any use of these elements and content (or parts thereof),
					extending beyond what is necessary for using the website by
					the user under normal circumstances, is inadmissible without
					the prior written consent of Blue Medical AG, the making
					available of the same does not entail any license or other
					right of usage for the benefit of the user. This applies in
					particular, but without limitation, to trademarks and other
					marks/signs, content, layout, design and interface of the
					website, databases, videos, photographs, texts and graphics.
				</p>
			</Container>
			<Container sectionName="imprint__wrap imprint__container imprint__data">
				<h2 className="copy-large--lh">Data protection</h2>
				<p>
					The following personal data of users may be collected and
					processed in the course of the use of the website/the
					services provided: (i) name, (ii) e-mail address, (iii)
					postal address, (iv) other contact details (telephone number
					etc).
					<br />
					<br />
					By accessing or using the website, the following (partly
					personal) data may also be transmitted to and processed by
					Blue Medical AG: (i) log-data like IP-address, date and time
					of access, information concerning the operating system and
					the internet browser used, website, from which the access
					occurred (“referrer”), (ii) cookies, (iii) location-specific
					information and information about the device used (depending
					on the settings of the device). Such personal data will be
					used solely for the purposes of providing the services to
					the user, for maintenance and improvement of services in the
					context of the website. Personal data will not be
					transferred to third parties (unless the user has given its
					authorisation). The user hereby expressly consent to the use
					of its personal data for the purposes and in the manner and
					scope described herein. The user has the right to demand
					information about how its personal data is used at any time
					and to correct such personal data if necessary. The user
					also has the right to withdraw its consent in respect of the
					use of its personal data, being contacted by Blue Medical AG
					via e-mail, as well as the use of analytic- and
					tracking-tools for the future. Please direct any such
					requests to the following address:
					welcome@blue-medical.swiss
				</p>
			</Container>
		</Layout>
	);
};

export default ImprintPage;
